<template>
  <div
    v-if="title"
    class="hm-tag text-truncate text-nowrap"
    :class="`hm-tag--${status}`"
    v-html="sanitizedTitle"
    v-bind="$attrs"
  ></div>
  <div
    v-else
    class="hm-tag text-truncate text-nowrap"
    :class="`hm-tag--${status}`"
    v-bind="$attrs"
  >
    <slot></slot>
  </div>
</template>

<script>
import { sanitize } from '@/utils/utils';

export default {
  name: 'HMTag',
  props: {
    title: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
  },
  computed: {
    sanitizedTitle() {
      if (!this.title) return '';
      return sanitize(this.title);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-tag {
  background-color: $lightest-gray;
  border-radius: $border-radius;
  padding: 4px 8px;
  display: inline-block;

  &.small-radius {
    border-radius: 3px;
  }

  &--active,
  &--published {
    color: $forest;
    background-color: lighten($forest, 60%) !important;
  }
  &--passed {
    color: white;
    background-color: $light-green;
  }
  &--failed {
    color: white;
    background-color: $coral;
  }
  &--review {
    color: white;
    background-color: $brown;
  }
  &--completed {
    color: $dark-gray;
  }
  &--past_due,
  &--processing {
    color: $mustard;
    background-color: lighten($mustard, 50%) !important;
  }
  &--cancelled,
  &--error {
    color: $coral;
    background-color: lighten($coral, 25%) !important;
  }
  &--paused {
    color: white;
    background-color: var(--brand-color, $coral) !important;
  }
}
</style>
